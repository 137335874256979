import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Creating a community can be challenging and time consuming. Focusing on key trends in your industry, bringing value to your community and finding engaging speakers can be the key to create a positive and engaged community in your local town.`}</p>
    <br />
    <p>{`How many times have you wanted to start something, but you don’t know how?`}</p>
    <p>{`How many times have you wanted to learn something new, but you don’t where to start?`}</p>
    <p>{`How many times have you wanted to meet relevant people in your industry?`}</p>
    <p>{`In my case: `}<em>{`so many times.`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`Have you ever experienced any of these points above?`}</p>
    </blockquote>
    <p>{`Being an Italian immigrant in Norway, it was difficult to find key developers in the area I was living. Difficult to find interesting talks in the existing communities. Interest is subjective, so that’s not criticism against the existing communities out there.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*IVjuE4sN4UHOWOqw",
        "alt": "Italy"
      }}></img></p>
    <p>{`That’s why I decided to start a meetup less than 3 years ago. It was centered around a trending topic in my industry: ReactJS and the Javascript ecosystem.`}</p>
    <p>{`It has been hard work to grow this community from 0 to 1300 in less than 3 years. All organic growth and no marketing at all.`}</p>
    <p>{`And always the same question come up over and over.`}</p>
    <h3>{`Domenico, how did you do this?`}</h3>
    <p>{`Well, it was just magic. I opened a new meetup page and magically 1300 developers registered for the next event.`}</p>
    <p>{`I wish….`}</p>
    <p>{`Not so easy. Let’s start saying that it takes time.`}</p>
    <p>{`It takes time to organize the meetup: from finding venues, food & drinks to finding the right speakers. It takes time to follow up with companies and speakers. It takes time to edit the videos of the speakers and upload them in Youtube (more if you are a beginner like me!)`}</p>
    <p>{`This is what I learnt organizing meetups in the last 3 years and how you can start a meetup tomorrow.`}</p>
    <p>{`There are few simple steps that can help you to grow a community in your own city.`}</p>
    <h2>{`TOPIC`}</h2>
    <p>{`First of all, find the key trends in your industry of expertise: for me, it was ReactJS in 2015 (a technology built at Facebook); for you could be something else. Just take few hours to explore what is the people talking in your industry.`}</p>
    <h2>{`YOU DON’T NEED TO BE THE EXPERT`}</h2>
    <p>{`You don’t need to be an expert in the topic of your meetup.
Your job will be to bring experts in front of your audience. You don’t even need to present once if it is not your thing.
Just focus on building and growing the community.`}</p>
    <p>{`However, if presenting is something you enjoy, you have already found a speaker for your next event.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/8000/0*nVibbpKFAIyIhyfO",
        "alt": "Image"
      }}></img></p>
    <h2>{`SPEAKERS`}</h2>
    <p>{`Talking about speakers. Find good speakers.`}</p>
    <h3>{`Easy to say Domenico, but where do you find these speakers?`}</h3>
    <p>{`Not so difficult as it seems. Sometimes, they have already given presentations to other meetups or conferences in your city. Sometime, it can be a friend of a friend that could be a perfect for your meetup.`}</p>
    <p>{`If you don’t have luck with that strategy, you could contact all the companies related to the topic of your meetup.`}</p>
    <b>How many speakers should we invite for each event?</b>
    <p>{`It depends. How long will the meetup last? What time are you planning to start the event? How long are the talks?`}</p>
    <p>{`Most important for me was the time of the actual event, because I am doing these events after people is coming out from their job.`}</p>
    <p>{`I found that two speakers for event is a good magic number for a successful meetup. The talks are 30–40 min long and I leave 10–15 between talks for mingling during the event.
3 speakers can work if you are good enough keep track of the time but the length of the talks could be much smaller.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*PXG3ySu8aol72eNV",
        "alt": "Image"
      }}></img></p>
    <h2>{`SPONSORS`}</h2>
    <p>{`Another important points is finding Event Sponsors.`}</p>
    <p>{`You will need good sponsors to succeed. Sponsors interested to help growing your community. What they can offer can change: someone will offer food & drinks. Some other sponsors will offer venues for your attendees.`}</p>
    <h2>{`FREE OR PAID EVENTS?`}</h2>
    <b>Keep it free</b>: at least, initially. Your main focus should be on creating a positive and growing community. Not monetizing on them; there will be the right time on that.
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*0v3aHD70VurQqIgy",
        "alt": "Image"
      }}></img></p>
    <h2>{`JAB, JAB, JAB, RIGHT HOOK`}</h2>
    <p>{`Focus on bringing value on each person in the meetup. Before, during and after the meetup.`}</p>
    <p>{`When you start a new community, your main focus should on bringing more and more value to your audience. Network, ask questions to participants during your events, and try to find new ways to make your meetups more engaging and valuable for your attendees.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Question of the day: Which kind of meetup are you organizing (or you would like to start?)`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/6000/0*-HOLl1kSNB3OsBYJ",
        "alt": "Image"
      }}></img></p>
    <p>{`If you are in Oslo, join us to the `}<a parentName="p" {...{
        "href": "https://www.meetup.com/ReactJS-Oslo-Meetup/"
      }}>{`“React JS Oslo” Meetup`}</a>{` for for great developers talks.
I am organizing them one each month and we would love you there.
Official page: `}<a parentName="p" {...{
        "href": "http://reactjsnorway.com/"
      }}>{`React JS Norway`}</a></p>
    <p>{`If you are more interested in management talks for CTOs and tech leaders, join me to the `}<a parentName="p" {...{
        "href": "https://www.meetup.com/Leadership-in-Tech/"
      }}>{`“Leadership in Tech” Meetup`}</a>{`. `}</p>
    <p>{`It is more Q&A with Key Opinion Leader in the industry than people presenting something. Another super engaged community to be part of.`}</p>
    <p>{`Well, last thing: If you arrived until here, thank you so much for your support and time that you spend in this page.`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      